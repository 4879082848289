/**
 * @imarcsgroup/client:src/entrypoints/end.js
 */

/**
 * Setup API End.
 */
(async function apiSetupEnd() {
  const { $, $$ } = global;
  if(!(
    $$ && typeof $$ === 'object' &&                         // Must have global $$ object.
        /^\d+\.\d+\.\d+$/.test($$.VERSION) &&               // Must have a version attached.
        $$.setup && typeof $$.setup === 'object' &&         // Must have the setup object.
        $$.log && typeof $$.log.info === 'function' &&      // Must have a logger attached.
        $$.setup.library === true                           // The api-client-library should have been setup.
  )) {
    throw new Error('api-client-end requires api-client-library.');
  }
  if(!$) {
    throw new Error('api-client-end requires jquery.');
  }
  const body = $('body');
  if(!body.length) {
    throw new Error('api-client-end must inside of body tag.');
  }
  if($$.setup.start !== true) {
    throw new Error('api-client-end must be included after api-client-start.');
  } else if($$.setup.end === true) {
    throw new Error('api-client-end must only be included once.');
  }
  $$.setup.end = true;
  global.dispatchEvent(new Event('api-setup-end'));
})();
